import {
  filter,
  find,
  flatMap,
  identity,
  includes,
  map,
  pickBy,
  reduce,
  sortBy,
  uniq,
} from 'lodash';
import { getMarketTemplateName } from '@/services/helpers/market-template-mapper';

export const makeMarketCodeToMarketGroupsMap = (marketGroups) => reduce(
  marketGroups,
  (result, { code, markets: { nodes } }) => ({
    ...result,
    ...reduce(
      nodes,
      (groupResult, { marketCode }) => ({
        ...groupResult,
        [marketCode]: [
          ...(result[marketCode] || []),
          ...(groupResult[marketCode] || []),
          code,
        ],
      }),
    ),
  }),
  {},
);

export const categorizeMarketTemplates = (marketCodeToMarketGroupsMap, marketTemplates) => reduce(
  marketTemplates,
  (result, marketTemplate) => {
    const { template: { code } } = marketTemplate;
    const groups = marketCodeToMarketGroupsMap[code] || ['-'];
    return [
      ...result,
      {
        ...marketTemplate,
        groups,
      },
    ];
  },
  [],
);

export const parseMarket = (marketTemplate) => ({
  id: marketTemplate.marketTemplateId,
  name: getMarketTemplateName(marketTemplate),
  groups: marketTemplate.groups,
});

export const parseMarketList = (marketGroups, marketTemplates) => {
  const marketCodeToMarketGroupsMap = makeMarketCodeToMarketGroupsMap(marketGroups);
  const categorizedMarketTemplates = categorizeMarketTemplates(marketCodeToMarketGroupsMap, marketTemplates);
  return map(categorizedMarketTemplates, parseMarket);
};

export const parseMarketGroup = (marketGroup) => ({
  id: marketGroup.code,
  name: marketGroup.displayName,
});

export const parseMarketGroups = (marketGroups, marketList) => {
  const marketGroupsWithUngroupedGroup = [
    ...marketGroups,
    {
      code: '-',
      displayName: 'Ungrouped',
    },
  ];
  const marketTemplatesGroupCodes = pickBy(uniq(flatMap(marketList, 'groups')), identity);
  const marketGroupsWithMarketTemplates = filter(marketGroupsWithUngroupedGroup, ({ code }) => includes(marketTemplatesGroupCodes, code));
  const marketGroupsWithAllGroup = [
    {
      code: '',
      displayName: 'All',
    },
    ...marketGroupsWithMarketTemplates,
  ];
  return map(marketGroupsWithAllGroup, parseMarketGroup);
};

export const parseSelectedMarketList = (marketTemplateIds, marketList) => reduce(
  marketTemplateIds,
  (result, id) => {
    const foundMarket = find(marketList, { id });
    if (!foundMarket) return result;
    return [...result, foundMarket];
  },
  [],
);

export const filterMarketListByMarketGroupId = (marketList, marketGroupId = '') => {
  if (!marketGroupId) return marketList;
  return filter(marketList, ({ groups }) => includes(groups, marketGroupId));
};

export const makeMarketGroupSortIndexMap = (marketGroups) => reduce(
  marketGroups,
  (result, { id }, index) => ({
    ...result,
    [id]: index,
  }),
  {},
);

export const sortMarketList = (marketList, marketGroups = []) => {
  const marketGroupSortIndexMap = makeMarketGroupSortIndexMap(marketGroups);
  return sortBy(
    marketList,
    (market) => marketGroupSortIndexMap[market.groups?.[0]] || Number.MAX_SAFE_INTEGER,
    'name',
  );
};
