import {
  flatMap,
  values,
  isNil,
  orderBy,
  reverse,
  map,
  filter,
  find,
  toLower,
  includes,
  each,
  replace,
  trim,
  keys,
} from 'lodash';
import sportIds from '@/services/helpers/sports';
import eventHelpers from '@/services/helpers/event';
import basketballHelpers from '@/services/helpers/basketball';
import footballHelpers from '@/services/helpers/football';
import hockeyHelpers from '@/services/helpers/hockey';
import randomUUID from '@/utils/random-uuid';
import { appendOrdinalSuffix } from '@/utils/number';

const {
  BASKETBALL_ID,
  FOOTBALL_ID,
  HOCKEY_ID,
} = sportIds;

/*
 * Basketball implementation details
 */
const basketballActionTypes = {
  UNKNOWN: 'UNKNOWN',
  SCORED_2PT: 'SCORED_2PT',
  SCORED_3PT: 'SCORED_3PT',
  REBOUND: 'REBOUND',
  OFF_REBOUND: 'OFF_REBOUND',
  DEF_REBOUND: 'DEF_REBOUND',
  SCORED_FT: 'SCORED_FT',
  MISSED_FT: 'MISSED_FT',
  MISSED_2PT: 'MISSED_2PT',
  MISSED_3PT: 'MISSED_3PT',
  STEAL: 'STEAL',
  FOUL: 'FOUL',
  TECH_FOUL: 'TECH_FOUL',
  FLAG_FOUL: 'FLAG_FOUL',
};

const basketballActionTemplates = {
  [basketballActionTypes.SCORED_2PT]: '({team}) {playerA} Scored 2 pointer, Assisted by {playerB}',
  [basketballActionTypes.SCORED_3PT]: '({team}) {playerA} Scored 3 pointer, Assisted by {playerB}',
  [basketballActionTypes.REBOUND]: '({team}) {playerA?} Rebound',
  [basketballActionTypes.OFF_REBOUND]: '({team}) {playerA?} Offensive Rebound',
  [basketballActionTypes.DEF_REBOUND]: '({team}) {playerA?} Defensive Rebound',
  [basketballActionTypes.SCORED_FT]: '({team}) {playerA} Scored Free Throw',
  [basketballActionTypes.MISSED_FT]: '({team}) {playerA} Missed Free Throw',
  [basketballActionTypes.MISSED_2PT]: '({team}) {playerA} Missed 2pt Attempt',
  [basketballActionTypes.MISSED_3PT]: '({team}) {playerA} Missed 3pt Attempt',
  [basketballActionTypes.STEAL]: '({team}) {playerA} Stole From {playerB}',
  [basketballActionTypes.FOUL]: '({team}) {playerA} Fouled {playerB}',
  [basketballActionTypes.TECH_FOUL]: '({team}) {playerA} Technical Foul',
  [basketballActionTypes.FLAG_FOUL]: '({team}) {playerA} Flagrant Foul',
};

const parseBaskeballActions = (event) => {
  const actions = event?.actions || [];

  const filteredActions = filter(actions || [], ({ type }) => type !== 'UNKNOWN');
  const mappedActions = map(filteredActions, (action) => {
    const {
      id,
      type,
      clock,
      ...fields
    } = action;

    return {
      id,
      type,
      message: fields,
      clock,
    };
  });
  return reverse(mappedActions);
};

const parseBasketballActionParams = (action, options) => ({
  ...(action?.clock || {}),
  ...(action?.message || {}),
  competitionType: options?.event?.competitionType || '',
  periodLengthInMinutes: options?.event?.periodLengthInMinutes,
  overtimeLengthInMinutes: options?.event?.overtimeLengthInMinutes,
  sportId: options?.event?.sportId || '',
  team: options?.event?.[toLower(action?.message?.team)]?.shortName || 'N/A',
  playerA: find(options?.squad, { playerIndex: action?.message?.playerIndex1 })?.shortName || action?.message?.playerIndex1 || 'N/A',
  playerB: find(options?.squad, { playerIndex: action?.message?.playerIndex2 })?.shortName || action?.message?.playerIndex2 || 'N/A',
});

/*
 * Football implementation details
 */
const footballActionTypes = {
  UNKNOWN: 'UNKNOWN',
  OFFENSE: 'OFFENSE',
  DEFENSE: 'DEFENSE',
  MIDFIELD: 'MIDFIELD',
};

const footballActionTemplates = {
  [footballActionTypes.UNKNOWN]: '({team}) {down} and {distanceToNewFirst}',
  [footballActionTypes.OFFENSE]: '({team}) {down} and {distanceToNewFirst} at {yardsToEndZone}',
  [footballActionTypes.DEFENSE]: '({team}) {down} and {distanceToNewFirst} at {yardsToEndZone}',
  [footballActionTypes.MIDFIELD]: '{down} at midfield',
};

const parseFootballDrivePeriod = (period) => {
  if (period === 'PERIOD_1') return 'IN_FIRST_PERIOD';
  if (period === 'PERIOD_2') return 'IN_SECOND_PERIOD';
  if (period === 'PERIOD_3') return 'IN_THIRD_PERIOD';
  if (period === 'PERIOD_4') return 'IN_FOURTH_PERIOD';
  return period;
};

const getClockPeriodIndex = (period) => {
  if (period === 'IN_FIRST_PERIOD') return 1;
  if (period === 'IN_SECOND_PERIOD') return 2;
  if (period === 'IN_THIRD_PERIOD') return 3;
  if (period === 'IN_FOURTH_PERIOD') return 4;
  return 0;
};

const parseFootballActions = (event) => {
  const drives = event?.drives || {};
  const competitionId = event?.competition?.id || '';
  const yardsToEndZone = event?.yardsToEndZone || 0;

  const flattenedDrives = flatMap(drives, (drive) => values(drive));
  const flattenedPlays = flatMap(
    flattenedDrives,
    (drive) => flatMap(drive.plays, (play) => {
      const safeYardsToEndZone = isNil(play.startingPosition?.yardsToEndZone) ? yardsToEndZone : play.startingPosition?.yardsToEndZone;
      const correctYardsToEndZone = competitionId === '6b3e9b3b-0b2d-4a1f-bb0d-4e87ad704738' ? play.startingPosition?.yardsToEndZone : safeYardsToEndZone;

      if (isNil(correctYardsToEndZone)) {
        return {
          id: randomUUID(),
          type: footballActionTypes.UNKNOWN,
          message: {
            team: drive.team,
            down: appendOrdinalSuffix(play.startingPosition?.down),
            distanceToNewFirst: play.startingPosition?.distanceToNewFirst,
          },
          clock: {
            period: parseFootballDrivePeriod(play.startingPeriod),
            gameTimeElapsedInPeriod: play.startingTimeInPeriod,
          },
        };
      }

      if (correctYardsToEndZone > 50) {
        return {
          id: randomUUID(),
          type: footballActionTypes.OFFENSE,
          message: {
            team: drive.team,
            down: appendOrdinalSuffix(play.startingPosition?.down),
            distanceToNewFirst: play.startingPosition?.distanceToNewFirst,
            yardsToEndZone: 100 - correctYardsToEndZone,
          },
          clock: {
            period: parseFootballDrivePeriod(play.startingPeriod),
            gameTimeElapsedInPeriod: play.startingTimeInPeriod,
          },
        };
      }

      if (correctYardsToEndZone < 50) {
        return {
          id: randomUUID(),
          type: footballActionTypes.DEFENSE,
          message: {
            team: drive.team,
            down: appendOrdinalSuffix(play.startingPosition?.down),
            distanceToNewFirst: play.startingPosition?.distanceToNewFirst,
            yardsToEndZone: correctYardsToEndZone,
          },
          clock: {
            period: parseFootballDrivePeriod(play.startingPeriod),
            gameTimeElapsedInPeriod: play.startingTimeInPeriod,
          },
        };
      }

      return {
        id: randomUUID(),
        type: footballActionTypes.MIDFIELD,
        message: {
          team: drive.team,
          down: appendOrdinalSuffix(play.startingPosition?.down),
        },
        clock: {
          period: parseFootballDrivePeriod(play.startingPeriod),
          gameTimeElapsedInPeriod: play.startingTimeInPeriod,
        },
      };
    }),
  );
  return reverse(orderBy(flattenedPlays, [(play) => getClockPeriodIndex(play.clock.period), 'clock.gameTimeElapsedInPeriods']));
};

const parseFootballActionParams = (action, options) => ({
  ...(action?.clock || {}),
  ...(action?.message || {}),
  quarterDuration: options?.event?.quarterDuration,
  overtimeDuration: options?.event?.overtimeDuration,
  sportId: options?.event?.sportId || '',
  team: options?.event?.[toLower(action?.message?.team)]?.shortName || 'N/A',
});

/*
 * Ice hockey implementation details
 */
const hockeyActionTypes = {
  GOAL: 'GOAL',
  SHOT_OFF_TARGET: 'SHOT_OFF_TARGET',
  SHOT_BLOCKED: 'SHOT_BLOCKED',
  SAVE: 'SAVE',
  PENALTY_2M: 'PENALTY_2M',
  PENALTY_5M: 'PENALTY_5M',
  PENALTY_10M: 'PENALTY_10M',
};

const hockeyActionTemplates = {
  [hockeyActionTypes.GOAL]: '({team}) Goal Scored by {playerA}',
  [hockeyActionTypes.SHOT_OFF_TARGET]: '({team}) Shot off target by {playerA}',
  [hockeyActionTypes.SHOT_BLOCKED]: '({team}) Shot in block by {playerA}',
  [hockeyActionTypes.SAVE]: '({team}) Saved by {playerA}',
  [hockeyActionTypes.PENALTY_2M]: '2m penalty for {team}',
  [hockeyActionTypes.PENALTY_5M]: '5m penalty for {team}',
  [hockeyActionTypes.PENALTY_10M]: '10m penalty for {team}',
};

const parseHockeyActions = (event) => {
  const actions = event?.actions || [];

  const filteredActions = filter(actions || [], (action) => action && action.type !== 'UNKNOWN');
  const mappedActions = map(filteredActions, (action) => {
    const {
      id,
      type,
      clock,
      ...fields
    } = action;

    return {
      id,
      type,
      message: fields,
      clock,
    };
  });
  return reverse(mappedActions);
};

const parseHockeyActionParams = (action, options) => ({
  ...(action?.clock || {}),
  ...(action?.message || {}),
  periodLengthInMinutes: options?.event?.periodLengthInMinutes,
  overtimeLengthInMinutes: options?.event?.overtimeLengthInMinutes,
  sportId: options?.event?.sportId || '',
  team: options?.event?.[toLower(action?.message?.team)]?.shortName || 'N/A',
  playerA: find(options?.squad, { playerIndex: action?.message?.playerIndex1 })?.shortName || action?.message?.playerIndex1 || 'N/A',
  playerB: find(options?.squad, { playerIndex: action?.message?.playerIndex2 })?.shortName || action?.message?.playerIndex2 || 'N/A',
});

/*
 * Internal play by play helpers
 */
const getValueOrFallback = (value) => value ?? '?';

const getActionTemplates = (sportId) => {
  switch (sportId) {
  case BASKETBALL_ID:
    return basketballActionTemplates;
  case FOOTBALL_ID:
    return footballActionTemplates;
  case HOCKEY_ID:
    return hockeyActionTemplates;
  default:
    return {};
  }
};

const parseActionParams = (action, options) => {
  switch (options?.event?.sportId || '') {
  case BASKETBALL_ID:
    return parseBasketballActionParams(action, options);
  case FOOTBALL_ID:
    return parseFootballActionParams(action, options);
  case HOCKEY_ID:
    return parseHockeyActionParams(action, options);
  default:
    return {};
  }
};

const makeAppendExtraMessage = (extraMessage) => (string) => (isNil(extraMessage) ? string : `${string} (${extraMessage})`);

/*
 * Exposed play by play functions
 */
export const isPlayByPlaySupported = (sportId) => includes(
  [
    BASKETBALL_ID,
    FOOTBALL_ID,
    HOCKEY_ID,
  ],
  sportId,
);

export const isPlayByPlayDisplayable = (event) => {
  if (!isPlayByPlaySupported(event?.sportId)) return false;
  return eventHelpers.isLive(event) && event?.period !== 'EVENT_COMPLETED';
};

export const parsePlayByPlayActions = (event) => {
  switch (event?.sportId || '') {
  case BASKETBALL_ID:
    return parseBaskeballActions(event);
  case FOOTBALL_ID:
    return parseFootballActions(event);
  case HOCKEY_ID:
    return parseHockeyActions(event);
  default:
    return [];
  }
};

export const formatPlayByPlayActionMessage = (template, args) => {
  let message = template;

  each(keys(args), (key) => {
    const value = getValueOrFallback(args[key]);
    const requiredKey = `{${key}}`;
    const optionalKey = `{${key}?}`;
    if (includes(message, optionalKey)) {
      const replacement = (value === 'N/A' ? '' : value);
      message = trim(replace(message, optionalKey, replacement));
    }
    if (includes(message, requiredKey)) {
      message = trim(replace(message, requiredKey, value));
    }
  });

  const appendExtraMessage = makeAppendExtraMessage(args.extraInfo);
  if (!message.includes(',')) return appendExtraMessage(trim(message));
  const [firstPart, secondPart] = message.split(',');
  if (secondPart.includes('N/A')) return appendExtraMessage(trim(firstPart));
  return appendExtraMessage(trim(message));
};

export const formatPlayByPlayActionClock = (args) => {
  switch (args?.sportId || '') {
  case BASKETBALL_ID:
    return basketballHelpers.getPeriodLabel(args);
  case FOOTBALL_ID:
    return footballHelpers.getPeriodLabel(args);
  case HOCKEY_ID:
    return hockeyHelpers.getPeriodLabel(args);
  default:
    return 'N/A';
  }
};

export const formatPlayByPlayAction = (action, options) => {
  const templates = getActionTemplates(options.event.sportId);
  const template = templates[action.type];
  const args = parseActionParams(action, options);

  if (!template) {
    console.warn('Unsupported action type:', action.type);
    return { message: `Unsupported action (${action.type})`, clock: '' };
  }

  return {
    message: formatPlayByPlayActionMessage(template, args),
    clock: formatPlayByPlayActionClock(args),
  };
};

export const formatPlayByPlayActions = (actions, options) => map(actions, (action) => formatPlayByPlayAction(action, options));
