/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { keys, isNil } from 'lodash';

/**
 * Transforms and normalizes event into an intermediary data structure from which statistics are created.
 *
 * The reason why this step is neccessary is because we want to have a standard data structure for
 * displaying statistics. Without this step, we would have to write separate components for each sport
 * because different sports use different data sturctures for statistics.
 *
 * @param {Object} event Event from which data is taken
 * @param {Array} [options.periods] Array of available periods
 * @param {Object} [options.fields] Statistic types to field names mapping
 * @param {Function} [options.transform] Function that transforms event data
 * @returns Returns new object that contains normalized event data
 */
export const createDataSet = (event, {
  periods,
  fields,
  totalFields,
  transform,
}) => {
  const statistics = {
    home: {},
    away: {},
  };

  for (const type in fields) {
    const field = fields[type];
    for (const period of periods) {
      statistics.home[type] = {
        ...(statistics.home[type] ?? {}),
        [period]: transform({
          period, field, data: event.home, value: event.home[field],
        }),
      };
      statistics.away[type] = {
        ...(statistics.away[type] ?? {}),
        [period]: transform({
          period, field, data: event.away, value: event.away[field],
        }),
      };
    }
  }

  for (const type in totalFields) {
    const field = totalFields[type];
    statistics.home[type].TOTAL = transform({
      period: 'TOTAL',
      field,
      data: event.home,
      value: event.home[field],
    });
    statistics.away[type].TOTAL = transform({
      period: 'TOTAL',
      field,
      data: event.away,
      value: event.away[field],
    });
  }

  return statistics;
};

/**
 * Creates statistics by period from specified data set.
 *
 * @param {Object} dataSet Standardized and normalized event data
 * @returns Returns new object that contains statistics by period
 */
export const createPeriodStatistics = (dataSet) => {
  const statistics = {};

  const fields = keys(dataSet.home);
  for (const type of fields) {
    const periods = keys(dataSet.home[type]);
    for (const period of periods) {
      statistics[period] = {
        ...(statistics[period] ?? {}),
        [type]: {
          homeValue: dataSet.home[type][period],
          awayValue: dataSet.away[type][period],
        },
      };
    }
  }

  return statistics;
};

/**
 * Creates total statistics from specified data set.
 *
 * @param {Object} dataSet Standardized and normalized event data
 * @returns Returns new object that contains total statistics
 */
export const createTotalStatistics = (dataSet) => {
  const statistics = {};

  const fields = keys(dataSet.home);
  for (const type of fields) {
    if (!isNil(dataSet.home[type].TOTAL) || !isNil(dataSet.away[type].TOTAL)) {
      statistics[type] = {
        homeValue: dataSet.home[type].TOTAL,
        awayValue: dataSet.away[type].TOTAL,
      };
      // eslint-disable-next-line no-continue
      continue;
    }

    if (!statistics[type]) {
      statistics[type] = {
        homeValue: 0,
        awayValue: 0,
      };
    }

    const homePeriods = keys(dataSet.home[type]);
    for (const period of homePeriods) {
      statistics[type].homeValue += dataSet.home[type][period];
    }

    const awayPeriods = keys(dataSet.away[type]);
    for (const period of awayPeriods) {
      statistics[type].awayValue += dataSet.away[type][period];
    }
  }

  return statistics;
};

/**
 * Creates statistics by period and calculates total statistics from specified data set.
 *
 * @param {Object} dataSet Standardized and normalized event data
 * @returns Returns new object that contains statistics
 */
export const createStatistics = (dataSet) => ({
  ...createPeriodStatistics(dataSet),
  TOTAL: createTotalStatistics(dataSet),
});
